<template>
    <v-sheet>
        <v-tabs
            v-model="tabComponente"
            dark
            fixed-tabs
            background-color="teal darken-3"
        >
            <v-tab v-for="(item, index) in form.tabsConteudo" :key="index">
                {{ index }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabComponente">
            <v-form :ref="formNome">
                <!--
                    DADOS
                 -->
                <v-tab-item eager class="py-4"> 
                    <v-row>
                        <v-col cols="2" sm="2">
                            <InputTextV2
                                v-model="
                                    form.tabsConteudo.dados
                                        .id_unidade_empreendimento
                                "
                                :form="form"
                                campo="id_unidade_empreendimento"
                            />
                        </v-col>
                        <v-col cols="4" sm="4">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.codigo"
                                :form="form"
                                campo="codigo"
                            />
                        </v-col>
                        <v-col cols="6" sm="6">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.empresa"
                                :form="form"
                                campo="empresa"
                            />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <TextAreaV2
                                v-model="
                                    form.tabsConteudo.dados.observacao
                                "
                                :form="form"
                                campo="observacao"
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-form>
        </v-tabs-items>

        <BuscaModal
            ref="BuscaModal"
            @inputAutoCompletaAtualizaValor="inputAutoCompletaAtualizaValor"
            @mensagemAlerta="$emit('mensagemAlerta', $event)"
        />
    </v-sheet>
</template>

<script>
import _ from 'lodash';

import { API_URL } from '@/config/configs';
import { Alerta, MetodoNovoOuAtualizaApi } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';
import { Maximo, Minimo, Obrigatorio } from '@/helpers/ValidadorCampo.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import InputTextV2 from '@/components/Form/InputTextV2.vue';
import TextAreaV2 from '@/components/Form/TextAreaV2.vue';

export default {
    components: {
        BuscaModal,
        InputTextV2,
        TextAreaV2
    },
    props: ['refBuscaModal'],
    async created() {
        this.formOriginal = _.cloneDeep(this.form);
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
        preencheFormulario(conteudo) {
            this.form.tabsConteudo.dados = _.pick(conteudo, Object.keys(this.form.tabsConteudo.dados));
        },
        FormataDadosParaEnviarForm() {
            let form = [];

            form = {
                dados: Object.assign(this.form.tabsConteudo.dados),
            };

            return form;
        },
        async novoForm() {
            try {
                if (this.urlNovoRegistro == '') {
                    this.$store.commit('FORM_ACAO', 'form-novo');
                    this.$store.dispatch('painelAbre');

                    return false;
                }

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlNovoRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-novo');

                        this.form.complemento = resposta.data.conteudo
                            .complemento
                            ? resposta.data.conteudo.complemento
                            : {};

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async consultaRegistro(id_registro) {
            try {
                this.tabComponente = 'Dados';

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlConsultaRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-atualiza');

                        this.preencheFormulario(resposta.data.conteudo.dados);

                        if (
                            resposta.data.conteudo.hasOwnProperty('complemento')
                        ) {
                            this.form.complemento = {
                                ...this.form.complemento,
                                ...resposta.data.conteudo.complemento,
                            };
                        }

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                Alerta(e.message, 'error');
            }
        },
        fechaForm() {
            try {
                this.$store.commit('FORM_ACAO', 'form-grid');
                this.limpaFormulario();
                this.$emit('datatableRecarrega');
                this.$store.dispatch('painelFecha');
                window.scrollTo(0, 0);
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async salvaForm() {
            try {
                if (!this.$refs[this.formNome].validate()) {
                    this.$emit(
                        'mensagemAlerta',
                        'Preencha corretamente todos campos',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario =
                            this.FormataDadosParaEnviarForm(
                                this.form.tabsConteudo
                            );

                        //console.log('envia form', conteudoFormulario);
                        //return;

                        let urlForm = conteudoFormulario.dados[
                            this.chavePrimaria
                        ]
                            ? `${API_URL.erp[this.moduloNome].salva}/${
                                  conteudoFormulario.dados[this.chavePrimaria]
                              }`
                            : `${API_URL.erp[this.moduloNome].salva}`;

                        let urlConfig = {
                            method: MetodoNovoOuAtualizaApi(
                                conteudoFormulario.dados[this.chavePrimaria]
                            ),
                            url: urlForm,
                            data: conteudoFormulario,
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$store.commit(
                                    'FORM_ACAO',
                                    'form-atualiza'
                                );

                                this.$emit(
                                    'mensagemAlerta',
                                    resposta.data.mensagem,
                                    'success'
                                );

                                this.preencheFormulario(
                                    resposta.data.conteudo.dados
                                );

                                if (
                                    resposta.data.conteudo.hasOwnProperty(
                                        'complemento'
                                    )
                                ) {
                                    this.form.complemento = {
                                        ...this.form.complemento,
                                        ...resposta.data.conteudo.complemento,
                                    };
                                }
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(
                    this.moduloNome
                );

                let ids = dados
                    .map(function (dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit(
                        'mensagemAlerta',
                        'Selecione um registro antes de apagar.',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`,
                            params: { [this.chavePrimaria]: ids },
                        };

                        let dados = await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$emit('datatableRecarrega');

                                this.$emit(
                                    'mensagemAlerta',
                                    `Registro ${ids} Deletado com sucesso`,
                                    'success'
                                );
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        limpaFormulario() {
            //this.$refs[this.formNome].reset();

            this.form = _.cloneDeep(this.formOriginal);
        },
        atualizaFormComplemento(complemento) {
            Object.keys(this.form.complemento).map((chave, idx) => {
                if (complemento[chave]) {
                    this.form.complemento[chave] = complemento[chave];
                }
            });

            this.formOriginal.complemento = this.form.complemento;
        },
        abrePesquisa(
            objetoClicado,
            objetoRetorno,
            formatoRetornado,
            titulo,
            datatableBusca
        ) {
            this.$refs['BuscaModal'].abreModal = true;
            this.$refs['BuscaModal'].titulo = titulo;
            this.$refs['BuscaModal'].objetoClicado = objetoClicado;
            this.$refs['BuscaModal'].objetoRetorno = objetoRetorno;
            this.$refs['BuscaModal'].formatoRetornado = formatoRetornado;
            this.$refs['BuscaModal'].datatableBusca = datatableBusca;
        },
        inputAutoCompletaAtualizaValor(objectoOpcoes, valorDefinido) {
            this.$refs['BuscaModal'].objetoClicado.atualizaValor(
                objectoOpcoes,
                valorDefinido
            );
        },
    },
    data() {
        return {
            apiUrl: API_URL,
            tabComponente: 'Dados',
            formNome: 'formUnidadeEmpreendimento',
            moduloNome: 'unidadeempreendimento',
            chavePrimaria: 'id_unidade_empreendimento',
            urlNovoRegistro: '',
            urlConsultaRegistro: 'listaPorIdForm',
            formOriginal: {},
            form: {
                tabsConteudo: {
                    dados: {
                        id_unidade_empreendimento: '',
                        codigo: '',
                        empresa: '',
                        observacao: '',
                    },
                },
                complementoOriginal: {},
                complemento: {},
                configuracao: {
                    campo: {},
                    campoConfiguracao: {
                        id_unidade_empreendimento: {},
                        codigo: {},
                        empresa: {},
                        observacao: {linhas: 10},
                    },
                    tamanho: {
                        id_unidade_empreendimento: {},
                        codigo: { min: 2, max: 3 },
                        empresa: { min: 2, max: 50 },
                        observacao: { max: 500 },
                    },
                    regra: {
                        id_unidade_empreendimento: [],
                        codigo: [Obrigatorio(), Minimo(2), Maximo(3)],
                        empresa: [Obrigatorio(), Minimo(2), Maximo(50)],
                        observacao: [Maximo(500)],
                    },
                    placeholder: {
                        id_unidade_empreendimento: 'Unidade Empreendimento',
                        codigo: 'Código',
                        empresa: 'Empresa',
                        observacao: 'Observação',
                    },
                    colunas: {},
                    obrigatorio: {
                        id_unidade_empreendimento: false,
                        codigo: true,
                        empresa: true,
                        observacao: false,
                    },
                    desativado: {
                        id_unidade_empreendimento: true,
                        codigo: false,
                        empresa: false,
                        observacao: false,
                    },
                    dataTableVuetify: {},
                },
            },
        };
    },
};
</script>

<style scoped>
</style>
