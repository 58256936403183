var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',[_c('v-tabs',{attrs:{"dark":"","fixed-tabs":"","background-color":"teal darken-3"},model:{value:(_vm.tabComponente),callback:function ($$v) {_vm.tabComponente=$$v},expression:"tabComponente"}},_vm._l((_vm.form.tabsConteudo),function(item,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s(index)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tabComponente),callback:function ($$v) {_vm.tabComponente=$$v},expression:"tabComponente"}},[_c('v-form',{ref:_vm.formNome},[_c('v-tab-item',{staticClass:"py-4",attrs:{"eager":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2","sm":"2"}},[_c('InputTextV2',{attrs:{"form":_vm.form,"campo":"id_unidade_empreendimento"},model:{value:(
                                _vm.form.tabsConteudo.dados
                                    .id_unidade_empreendimento
                            ),callback:function ($$v) {_vm.$set(_vm.form.tabsConteudo.dados
                                    , "id_unidade_empreendimento", $$v)},expression:"\n                                form.tabsConteudo.dados\n                                    .id_unidade_empreendimento\n                            "}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('InputTextV2',{attrs:{"form":_vm.form,"campo":"codigo"},model:{value:(_vm.form.tabsConteudo.dados.codigo),callback:function ($$v) {_vm.$set(_vm.form.tabsConteudo.dados, "codigo", $$v)},expression:"form.tabsConteudo.dados.codigo"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('InputTextV2',{attrs:{"form":_vm.form,"campo":"empresa"},model:{value:(_vm.form.tabsConteudo.dados.empresa),callback:function ($$v) {_vm.$set(_vm.form.tabsConteudo.dados, "empresa", $$v)},expression:"form.tabsConteudo.dados.empresa"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('TextAreaV2',{attrs:{"form":_vm.form,"campo":"observacao"},model:{value:(
                                _vm.form.tabsConteudo.dados.observacao
                            ),callback:function ($$v) {_vm.$set(_vm.form.tabsConteudo.dados, "observacao", $$v)},expression:"\n                                form.tabsConteudo.dados.observacao\n                            "}})],1)],1)],1)],1)],1),_c('BuscaModal',{ref:"BuscaModal",on:{"inputAutoCompletaAtualizaValor":_vm.inputAutoCompletaAtualizaValor,"mensagemAlerta":function($event){return _vm.$emit('mensagemAlerta', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }